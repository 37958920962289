import React, { Fragment, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ThemeColour } from "../../common/theme.types";
import { getItems, setOrder } from "../../store/items/items.actions";
import { RootState } from "../../store/root-reducer";
import Spinner from "../atomic/Spinner";
import logger from "../../utils/logger";
import ItemView from "./ItemView";
import { Item } from "../../store/items/items.types";
import ToggleSwitch from "../atomic/ToggleSwitch";

const ItemList = ({ items, getItems, setOrder }: Props) => {
  useEffect(() => {
    getItems();
  }, [getItems]);

  const [picked, setPicked] = useState<Item | null>(null);
  const [hover, setHover] = useState<Item["_id"] | null>(null);
  const [showChecked, setShowChecked] = useState<boolean>(true);

  const onPickup = (target: Item) => {
    logger.info({ target }, "onDragStart");
    setPicked(target);
  };
  const onDrop = (target: Item) => {
    logger.info({ target }, "onDrop");
    if (picked === null || target === picked) return;
    const A = items.items.sort((a, b) => a.order - b.order);
    const i = A.indexOf(target);
    if (i === -1) {
      logger.warn(
        "Something really weird happened!",
        "ItemList :: onDrop",
        false,
        { i, items: A }
      );
    } else if (i === 0) {
      logger.info("Dropped on first item ");
      setOrder(picked, target.order / 2);
    } else {
      logger.success("Dropped on " + i + "th item");
      setOrder(picked, (A[i].order + A[i - 1].order) / 2);
    }
    setPicked(null);
    setHover(null);
  };
  const allowDrop = (e: React.DragEvent) => {
    e.preventDefault();

    logger.info("BA");
  };

  const onDragEnter = (e: React.DragEvent, t: Item["_id"]) => {
    if (picked && picked._id !== t) setHover(t);
  };
  const onDragLeave = (e: React.DragEvent, t: Item["_id"]) => {
    // if (hover === t)
    setHover(null);
  };

  return items.loading ? (
    <Spinner variant={ThemeColour.Primary} />
  ) : (
    <div className="item-list">
      <ToggleSwitch
        name="Show checked?"
        value={showChecked}
        onToggle={() => setShowChecked(!showChecked)}
        variant={ThemeColour.Success}
        rounded
      />
      {items.items
        .filter((i) => !i.checked)
        .sort((a, b) => a.order - b.order)
        .concat(
          showChecked
            ? items.items
                .filter((i) => i.checked)
                .sort((a, b) => a.order - b.order)
            : []
        )
        .map((item, idx) => (
          <Fragment>
            <div
              className={`item-spacer${hover === item._id ? " hovered" : ""}`}
              onDrop={(e) => onDrop(item)}
              onDragOver={allowDrop}
              onDragEnter={(e) => onDragEnter(e, item._id)}
              onDragLeave={(e) => onDragLeave(e, item._id)}
            ></div>
            <ItemView
              item={item}
              key={idx}
              hovered={item._id === hover}
              onDragStart={onPickup}
              onDrop={onDrop}
              onDragOver={allowDrop}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
            />
          </Fragment>
        ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  items: state.items,
});

const connector = connect(mapStateToProps, {
  getItems,
  setOrder,
});

type Props = ConnectedProps<typeof connector> & {};

export default connector(ItemList);
