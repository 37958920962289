import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/root-reducer";
import { createItem } from "../../store/items/items.actions";
import { ItemData } from "../../store/items/items.types";
import logger from "../../utils/logger";
import { ORDER_GAP } from "../../common/vars";

const TitleOnlyItemForm = ({ items, createItem }: Props) => {
  const initialData = {
    title: "",
    order: 0,
  };
  const [data, setData] = useState<ItemData>(initialData);

  useEffect(() => {
    logger.info({ arr: items.items.map((i) => i.order) });
    setData((d) => ({
      ...d,
      order:
        items.items.map((i) => i.order).reduce((p, c) => Math.max(p, c), 0) +
        ORDER_GAP,
    }));
  }, [items.items, setData]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createItem(data);
    setData(initialData);
  };

  return (
    <form className="form create-item-form" onSubmit={onSubmit}>
      <div className="form-group">
        <input
          type="text"
          name="title"
          placeholder="Enter item here"
          value={data.title}
          onChange={onChange}
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({
  items: state.items,
});

const connector = connect(mapStateToProps, {
  createItem,
});

type Props = ConnectedProps<typeof connector> & {};

export default connector(TitleOnlyItemForm);
